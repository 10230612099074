function init() {
    const gmaps = document.getElementsByClassName("gmaps");
    if (gmaps[0]) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.onload = function() {
            // Cleanup onload handler
            script.onload = null;
            // do stuff with the loaded script!

            for (let i = 0; i < gmaps.length; i++) {
                let gmap = gmaps[i];
                let longtitude = parseFloat(gmap.getAttribute("data-longtitude"));
                let latitude = parseFloat(gmap.getAttribute("data-latitude"));
                let location = { lat: latitude, lng: longtitude };
                let map = new window.google.maps.Map(gmap, {
                    zoom: 17,
                    center: location,
                    gestureHandling: "greedy",
                    mapTypeId: "roadmap",
                    disableDefaultUI: true,
                    zoomControl: true
                });
                new window.google.maps.Marker({
                    position: location,
                    map: map
                });
            }
        };
        // Add the script to the DOM
        document.getElementsByTagName("head")[0].appendChild(script);

        // Set the `src` to begin transport
        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDIV2K334IU9UYi3QpEl5GIxut4gZZowUk&amp;";
    }
}

export default { init };
