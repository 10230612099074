import "../../css/pages/locationDetail.scss";
import gmaps from "../modules/gmaps";

import BasePage from "./base";

class LocationDetails extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }

    onReady() {
        super.onReady();
        gmaps.init();
    }
}

new LocationDetails();
